@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

.container {
  font-size: 1.2rem;
  border-radius: 15px;

  @media(min-width: $break-point-sm) {
    > div:last-of-type {
      padding: 55px 0;
    }
  }
}
.cleverSliderContainer {
  > button {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
  }
}

.noStaff {
  text-align: center;
  padding: 10px;
}

.searchContainer {
  display: none;
  text-align: center;
  @media(max-width: $break-point-sm) {
    display: block;
  }

  > div {
    display: inline-block;
    width: 80%;
    max-width: 350px;
    position: relative;
    margin-bottom: 5px;
    padding: 0 10px;
    @include mixins.searchBarMixin;
    > input {
      padding: 5px;
      display: inline-block;
      width: calc(100% - 20px);
    }
    > button {
      display: inline-block;
      width: 20px;
    }
  }
}

.searchbar {
  border: none;
  border-radius: 10px;
  font-size: 12px;
  padding: 5px 5px 5px 15px;
}

.staffBlockSlider4Items {
  width: 1060px !important;
}
.staffBlockSlider3Items {
  width: 795px !important;
}
.staffBlockSlider2Items {
  width: 530px !important;
}
.staffBlockSliderMobile {
  height: 230px !important;
  width: 80%;
}

.trackClassContainer {
  display: flex !important;
}

.childSlideContainer {
  display: flex !important;
  margin: 0px;
  align-items: center;
}

.scrollArrowClassName{
  span::before {
    background-color: white;
    border-radius: 50px;
  }
}

.slideItem {
  padding: 1rem 2rem;
  border-radius: 20px;
  display: inline-block;
  box-shadow: 2px 2px 5px 2px lightgray;
  margin-left: 5px;
  margin-top: 5px;
  width: 250px;
  height: 250px;
  overflow-y: auto;
  background-color: white;

  ul {
    padding-inline-start: 0px;
  }

}

.viewMore {
  text-align: center;
  display: none;
  @media(max-width: $break-point-sm) {
    display: block;
  }
}

@media(max-width: $break-point-sm) {
  .slideItem {
    width: 200px;
    height: 200px;
    touch-action: none;
  }
}

@media(max-width: 300px) {
  .slideItem {
    width: 175px;
    height: 175px;
  }
}
