.container {
  padding: 0.5rem;
  width: auto;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  min-height: 250px;

  .audienceSelector {
    &[data-disabled="true"] {
      label, input {
        cursor: not-allowed;
      }

      opacity: .6;
    }
  }
}

.selectedOrgs {
  margin-bottom: 5px;

  > div {
    display: inline-block;
    background: var(--blocks);
    color: var(--blocksTextColor);
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 5px;

    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }

  .addOrgButton {
    width: auto !important;
    background: lightgrey;
    color: black;

    > button {
      background: inherit;
      padding: 0 3px;
    }
  }
}

.searchDropdown {
  max-width: 300px;
  border: 1px solid lightgrey;
  margin: 2px 0 5px;
  border-radius: 5px;

  > div:first-of-type {
    padding: 5px;
  }

  > div:nth-of-type(2) { // dropdown
    box-shadow: 2px 4px 6px 1px rgba(72, 72, 72, 0.5);

    ul {
      padding: 5px;
    }
  }
}