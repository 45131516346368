.container {
  text-align: center;
  position: absolute;
  background: white;
  top: 10px;
  right: 10px;
  left: 10px;
  bottom: 10px;
  overflow-y: auto;
  padding-top: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  > button {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  > div {
    p {
      margin: 10px;
    }

    a {
      background-color: var(--blocks);
      color: var(--blocksTextColor);
      display: inline-block;
      padding: .5rem;
      border-radius: 7px;

      &:hover {
        color: var(--blocksTextColor);
      }
    }
  }

  small {
    margin: 10px;
    display: block;

    a {
      color: #337ab7 !important;
    }
  }
}

.iframeContainer {
  z-index: 100;
  width: 100%;
  height: 100%;
  max-height: -webkit-fill-available;
}

.loadingIndicator {
  position: absolute;
  z-index: 80;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);

  div {
    text-align: center;

    span {
      display: block;
      padding-bottom: 1rem;
    }

    button {
      color: #2d69f5;
    }
  }
}
