@use "../../../../../styles/variables.module" as *;

.lightThemePage {
  background-color: $admin-green-light;
  height: auto;
  min-height: 100%;
  padding: 5%;
  width: 100%;
  position: absolute;
  margin: 0 auto;
  color: $admin-blue-dark;

  h1 {
    font-weight: 700;
    font-size: 3rem;
  }

  input, textarea, div[role="textbox"] {
    // inputs (and markdown editor) should have a minimum font size of 16px. This not only improves accessibility but
    // and is also the threshold that iOS Safari does not enforce zooming into the element
    font-size: 16px;
  }
}

.darkThemePage {
  background-color: $admin-blue-light;
  height: 100%;
  padding-top: 5%;
  position: absolute;
  width: 100%;
}

.headerText {
  text-align: center;
  cursor: default;
  margin-bottom: 20px;
  background-color: $admin-blue-dark;
  color: #fff;

  img, span {
    border-radius: 50%;
    object-fit: contain;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    font-size: 1.6rem;
  }

  h2 {
    padding: 10px 0;
    font-weight: 700;
  }

  h3 {
    padding-bottom: 20px;
    font-size: 1.3rem;
  }
}

.bottomButtonContainer {
  padding: 10px 0;
  text-align: center;

  button {
    margin: 0 50px;
  }
}
