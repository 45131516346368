@use "../../../../styles/variables.module" as *;

.colorPickerContainer {
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  color: $admin-blue-dark;
  font-size: 1.2rem;
  height: 50px;
  text-align: right;

  div {
    display: inline-block;
    width: 40px;
    vertical-align: middle;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #333;
  }

  span:first-of-type {
    // hex code
    font-size: 0.9rem;
  }

  span:last-of-type {
    // down arrow
    font-size: 2rem;
    color: $admin-green-dark;
    vertical-align: middle;
  }

  * {
    margin-left: 8px;
  }
}

.colorDropdown {
  position: absolute;
  right: 60px;
  bottom: -100%;
  z-index: 1;
}
