@use "../../../../styles/variables.module";

.container {
  background: #f1f1f1;
  padding: 1px;

  > div {
    border: 3px dashed white;
    padding: 10px;
    text-align: center;
    vertical-align: top;
  }

  .previewContainer {
    display: inline-block;
  }

  .controls {
    display: inline-block;
    vertical-align: middle;

    > * {
      display: block;
    }
  }
}

.label {
  font-size: 1.4rem;
  font-weight: 600;
}

.button {
  color: #939393;
  padding: .25rem .5rem;
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 5px;
  margin-left: .25rem;
  transition: none;

  &:hover {
    background: darken(#939393, 10%);
    color: white;
  }
}