@use "../../../styles/variables.module" as *;

.container {
  background-color: white;
  &FloatingBlocks {
    background-color: rgb(255,255,255,0);
  }
  > button {
    margin: 0 auto;
    display: block;
  }

  > ul {
    padding: 0;
    margin: 0;
    text-align: left;

    > li {
      list-style: none;
      &:first-of-type {
        padding-top: 5px;
      }
    }
  }
}
