@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

$newsTerraceBackgroundColor: rgba(248, 248, 248, 0.75);

.leftPaneContainer {
  height: 100%;
  opacity: 0;
  transition: transform .25s ease, opacity .5s ease;
  transform: translateX(-20%);
  > div {
    height: 100%;
  }
}

.rightPaneContainer {
  height: 100%;
  opacity: 0;
  transition: transform .25s ease, opacity .5s ease;
  transition-delay: .5s;
  transform: translateX(20%);
  > div {
    height: 100%;
  }
  word-break: break-word;
}

.leftPaneTransitionContainer {
  @include mixins.translateTransition(translateX(-20%), translateX(0));
  @include mixins.opacityTransition(0, 1);
  flex-basis: 33.3333%;
  button {
    width: 100%;

    &:first-of-type {
      margin-top: 5px;
    }
    &:last-of-type {
      margin-bottom: 5px;
    }
  }
  @media (max-width: $break-point-md) {
    display: none;
  }
  @media (min-width: $break-point-sm) {
    border-right: 1px lightgrey solid;
  }
}

.rightPaneTransitionContainer {
  @include mixins.translateTransition(translateX(20%), translateX(0));
  @include mixins.opacityTransition(0, 1);
  overflow-x: hidden;
  flex-basis: 66.6667%;
  @media (max-width: $break-point-md) {
    flex-basis: 100%;
  }
  &HideLeftColumn {
    flex-basis: 100%;
  }
}

.newsItemScrollbarTerrace {
  div {
    position: relative !important;
  }
}

.newsSelectorContainer {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-height: 564px;
  background: $newsTerraceBackgroundColor;

  @media (min-width: $break-point-sm) {
    box-shadow: 2px 2px 2px #dcdcdc;
    border: 1px solid #dcdcdc;
    border-radius: 20px;
  }

  * {
    scroll-behavior: smooth;
  }

  // default color for links to prevent ADA compliance issues
  a {
    color: #0000EE;
  }

  .expandedView {
    @media (min-width: $break-point-sm) {
      padding-right: 20px;
    }
  }

  .scrollBarStyle {
    @media (max-width: $break-point-sm) {
      position: relative !important;
    }
  }

  .newsItemScroll {
    @media (max-width: $break-point-md) {
      display: none;
    }
  }

  > div {
    overflow-y: auto;
  }

  .newsItem {
    margin: 5px 10px;
    border-radius: 10px;
    border: 1px lightgrey solid;
    text-align: left;
    padding: 10px;
    background: #eee;
    transition: background-color 0.25s ease-in-out;

    &:hover {
      background: #e6e6e6;
    }

    &Selected {
      background: var(--secondaryNavBackground);
      color: var(--secondaryNavBackgroundTextColor);
      &:hover {
        background: var(--secondaryNavBackground);
      }
    }

    &Info {
      display: inline-block;
      max-width: 100%;
      h3 {
        padding-bottom: 5px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
      }
      .dateContainer {
        margin-bottom: 10px;
        font-size: 85%;
      }
    }
  }
}

.viewMoreButton {
  > div {
    margin: 0 !important;
    padding: 2rem 0 2rem 0;
    > button {
      width: auto;
    }
  }
}

.schoolFeedList {
  background: transparent !important;
}
