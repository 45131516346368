@use "../../../../../styles/variables.module" as *;

.featureSection {
  background: white;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 5px;
  font-weight: 600;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0 10px;
  }

  > span {
    line-height: 2rem;
  }
}

.gridTypeContainer {
  list-style: none;
  padding: 0;

  li:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  .radioButton {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    vertical-align: bottom;

    .radioButton {
      margin-right: .5rem;
    }

    &:hover input ~ span {
      background-color: #ccc;
    }

    & input:checked ~ span {
      background-color: #2196F3;

      &:after {
        display: block;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    span {
      height: 25px;
      width: 25px;
      background-color: #eee;
      border-radius: 50%;

      &::after {
        content: "";
        position: absolute;
        display: none;
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }

  label, .previewLink {
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
  }

  .previewLink {
    background: white;
    padding: .25rem .5rem;
    border: 2px solid $admin-blue-dark;
    color: $admin-blue-dark;
    border-radius: 10px;
    display: inline-block;
    margin-left: 1rem;
    transition: background .25s ease;

    &:hover {
      background: $lightgray;
    }
  }

  img {
    max-width: 200px;
  }
}

.tag {
  font-size: .9rem;
  background: $admin-blue-dark;
  color: white;
  padding: .15rem .25rem;
  border-radius: 5px;
}

.tagPosition {
  margin: 5px 5px 5px 0;
  vertical-align: bottom;
}

.featureButton {
  background: $admin-green-dark;
  color: white;
  padding: 5px;
  border-radius: 5px;

  &Remove {
    background: $faded-gray;
  }
}
