.switchContainer {
  > div {
    margin-left: 1rem;
    vertical-align: middle;
  }
}

.editButtons {
  margin-top: 5%;
  padding: 1rem;
  text-align: center;

  button {
    font-weight: 600;
    background: var(--blocks);
    color: var(--blocksTextColor);
    padding: 6px 12px;
    border-radius: 5px;

    span {
      margin-right: 5px;
    }

    &:first-of-type {
      margin-right: 1rem;
    }
  }
}
