@use "../../../../styles/variables.module" as *;

.editButton {
  background: $admin-blue-dark;
  color: white;
  border: 1px solid $admin-blue-dark;
  padding: 4px 6px;
  border-radius: 100%;
  margin-left: 5px;
  vertical-align: middle;
  transition: none;

  &:hover {
    background: white;
    color: $admin-blue-dark;
  }
}
