.toggle {
  position: relative;
  display: inline-block;
  background: #e5e5e5;
  border-radius: 25px;

  input {
    visibility: hidden;
    position: absolute;
  }

  input:checked+label {
    font-weight: 600;
  }
  input:not(:checked) + label {
    color: grey;
  }

  label {
    font-weight: 400;
    z-index: 2;
    position: relative;
    padding: 6px 18px;
    cursor: pointer;
    margin: 0;
  }

  div {
    border-radius: 25px;
    position: absolute;
    background: #b4b4b4;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: left .25s ease;
  }
}