$red: rgba(243, 84, 118, 0.5);
$green: rgba(49, 140, 61, 0.5);

.input {
  border: 2px solid $red !important;
  &:focus {
    border-color: $red !important;
    box-shadow: 0 0 4px $red !important;
  }

  &Valid {
    border-color: $green !important;
    &:focus {
      border-color: $green !important;
      box-shadow: 0 0 4px $green !important;
    }
  }
}

.div {
  border: 2px solid $red !important;
  &Valid {
    border: 2px solid $green !important;
  }
}