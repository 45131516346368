@use "../../../styles/variables.module" as *;

.container {
  position: relative;

  > span {
    padding: .5rem;
  }

  input {
    border: none;
    font-size: inherit;
    padding: 0;
    background-color: inherit;
  }

  > div { // dropdown
    position: fixed;
    width: 188px;
    box-shadow: $box-shadow;
    background-color: white;
    z-index: 2;
    max-height: 200px;
    overflow-y: scroll;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: .5rem 1rem;
        &[data-selected="true"] {
          background-color: #f6f6f6;
        }
      }
    }
  }
}
