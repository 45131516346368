@use "../../../../styles/variables.module" as *;

.searchContainer {
  ul {
    text-align: left;
    padding: 0;

    li {
      list-style: none;
      padding: 5px;

      &:hover {
        background-color: var(--blocks);
        color: var(--blocksTextColor);
      }
    }
  }

  > div:nth-of-type(2) { // results container
    box-shadow: $box-shadow;
    text-align: center;

    span {
      padding: 1rem 0;
      display: block;
    }
  }
}

.blockDisplayContainer {
  margin-bottom: 5px;
}
