@use "../../../styles/variables.module" as *;

.container {
  position: relative;
  display: inline-block;

  select {
    visibility: hidden;
  }

  > button {
    background-color: $clever-lgray;
    border: 1px solid $lightgray;
    padding: 3px 7px;
    border-radius: 5px;
    height: 35px;
  }

  select {
    width: 0;
    height: 0;
  }

  > div {
    box-shadow: $box-shadow;
    display: inline-block;
    top: 40px;
    left: 0;
    position: absolute;
    min-width: 150px;
    border-radius: 5px;
    background: white;
    z-index: 1400;

    ul {
      padding: 0;
      margin: 0;

      .option {
        list-style: none;
        padding: .5rem;
        cursor: pointer;
        white-space: nowrap;

        &:first-of-type, &Category {
          font-weight: 700;
        }

        input {
          vertical-align: text-bottom;
          margin-right: .5rem;
          pointer-events: none;
          transform: translateY(-20%);
        }

        &:hover {
          background-color: $clever-lgray;
        }

        &Selected {
          background-color: #337ab7;
          color: white;

          &:hover {
            background-color: #337ab7;
          }
        }
      }
    }
  }
}
