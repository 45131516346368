@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";

:export {
  rowHeight: $row-height;
}

$imageWidth: 100%;
$dateWidth: 40%;

.blockContainer {
  &:hover{
    .link {
      transform: scale(1.05);
    }
    .editButton {
      transform: scale(1.1);
      transition: transform .5s ease-in-out;
      visibility: visible;
    }
  }
}

.container {
  transition: all .5s ease-in-out;
  background-color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 22%);
  height: 200px;

  &:hover{
    transform: scale(1.02);
  }

  .contentContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    mask-image: linear-gradient(180deg, #000 150px, transparent);

    &BlurBottom {
      -webkit-mask-image: linear-gradient(180deg,#000 60%,transparent);
      mask-image: linear-gradient(180deg,#000 60%,transparent);
    }

    &Hidden {
      opacity: .5;
    }

    .innerContentContainer {
      padding-right: 15px;
      width: 100%;

      @media (max-width: $break-point-sm) {
        display: flex;
        flex-direction: column;
        padding-right: 0px;
      }

      .titleContainer {
        margin-left: 15px;
        color: $black;

        h2 {
          overflow: hidden;
          line-height: inherit;
          margin-bottom: 6px;
          font-weight: 700;
          font-size: $terrace-body-font-size;
        }

        @media (max-width: $break-point-sm) {
          padding-right: 15px;
          font-size: 16px;
        }
      }
      .dateContainer {
        margin: 0px 0px 15px 15px;
        color: $gray;

        div {
          display: inline-block;
          font-size: 1rem;
        }

        @media (max-width: $break-point-sm) {
          padding-right: 15px;
        }
      }
      .dateLocationContainer {
        margin-left: 15px;
        font-size: 10px;
        margin-bottom: 10px;

        img { // file/folder icon
          width: 18px;
          height: 18px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .descriptionContainer {
        margin-left: 15px;
        font-size: $terrace-body-font-size;
        color: $gray;
        word-break: break-word;

        ul,ol li {
          position: relative;
          left: 1em;
        }

        @media (max-width: $break-point-sm) {
          padding-right: 15px;
          font-size: 16px;
        }
      }
    }
  }
}

.courseWorkImageContainer {
  padding: 10px;
  width: 150px;
  float: left;
  margin-right: 15px;
  position: relative;
  text-align: center;
  background: #e6e6e6;

  > div:only-child {
    // increase size of icon when there's only one
    svg {
      width: 80px;
    }
    span {
      font-size: 80px;
    }
  }

  @media (max-width: $break-point-sm) {
    width: 100%;
    > div {
      display: inline-block;
      width: 50%;
    }
  }
  @media (min-width: $break-point-sm) {
    &GreaterThanTwo {
      > div {
        display: inline-block;
        width: 50%;
      }
    }
  }
  svg {
    width: 50px;
  }
  span {
    font-size: 50px;
  }
}

.imageContainer {
  height: fit-content;
  width: 50%;
  float: left;
  margin-right: 15px;
  position: relative;

  > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: $black;
  }

  @media (max-width: $break-point-sm) {
    width: 100%;
  }
}

.link {
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
  margin-inline: auto;

  &WithDate {
    right: (100% - $dateWidth) * 0.5;
    margin-inline: unset;
    width: unset;
  }
  @media (max-width: $break-point-sm) {
    right: unset;
    width: 100%;
    margin-inline: auto;
  }
}

.image {
  width: $imageWidth;
  object-fit: contain;
  float:left;

  @media (max-width: $break-point-sm) {
    width: 100%;
    margin-right: 0px;
  }
}

.organizationTitle {
  margin: 15px 0px 0px 15px;
  color: $black;
  font-size: 1rem;
}

.button{
  background-color: var(--blocks);
  padding: 3px 7px;
  border-radius: 12px;
  color: var(--blocksTextColor);
}

.dateMedia {
  min-width: 175px;
  min-height: 100px;
  position: relative;
  background: var(--blocks);
  color: var(--blocksTextColor);
  text-align: center;
  font-size: $terrace-body-font-size;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px;
  float: left;
  height: 100%;
  margin-right: 15px;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: $break-point-sm) {
    margin-right: 0px;
  }

  span {
    display: block;
    width: 100%;
  }

  & + div { // content container when media is present
    display: inline-block;
    margin-left: 0;
    @media (max-width: $break-point-sm) {
      margin-left: 15px;
    }
  }
}

.editButton {
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 5px;
  visibility: hidden;
}

.hiddenButton {
  background: $admin-blue-dark;
  color: white;
  border: 1px solid $admin-blue-dark;
  padding: 4px 4px;
  border-radius: 100%;
  margin-left: 5px;
  vertical-align: middle;
  transition: none;

  &:hover {
    background: white;
    color: $admin-blue-dark;
  }
}

