@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
  height: 100%;
  @include mixins.translateTransition(translateY(-110px), translateY(0));

  .exitIcon {
    float: right;
    margin-right: 15px;
    margin-top: 5px;
    cursor: pointer;
  }

  .notification {
    width: 90%;
    min-height: 80px;
    //max-height: 100px;
    margin: 20px auto;
    color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 2px #333;
    text-align: center;
    text-shadow: 1px 1px 1px #666;
    transition: all 250ms cubic-bezier(.87,-.41,.19,1.44);

    h3 {
      padding: 20px;
    }

    &Success {
      background-color: $admin-green-dark;
    }

    &Error {
      background-color: #B15555;
    }
  }

  .loadingContainer {
    margin-top: 40vh;
    width: 100%;
    text-align: center;

    h2 {
      font-size: 2.3rem;
      font-weight: 600;
      text-shadow: 1px 1px 1px #666;
    }

    span {
      font-size: 5rem;
      color: $admin-blue-dark;
    }
  }
}
