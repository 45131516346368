@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";

.container {
  margin: 10px 0px 10px 0px;

  button:first-of-type {
    margin-right: 40px;
  }
}

.editButtonContainer {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 10px;

  button {
    min-width: 100px;

    padding: .5rem 1rem;
    border-radius: 15px;
    cursor: pointer;
    transition: all .25s ease;
    margin-right: 25px;
    &:hover {
      color: white;
      background-color: $admin-blue-dark;
    }
  }
  .saveButton {
    button {
      @include mixins.buttonColor(var(--secondaryNavBackground), var(--secondaryNavBackgroundTextColor));
      border: 2px solid var(--secondaryNavBackground);
      &:hover {
        @include mixins.buttonColor(var(--secondaryNavBackgroundTextColor), var(--secondaryNavBackground));
      }
    }
  }
  .cancelButton {
    button{
      @include mixins.buttonColor(var(--secondaryNavBackgroundTextColor), var(--secondaryNavBackground));
      border: 2px solid var(--secondaryNavBackground);
      &:hover {
        @include mixins.buttonColor(var(--secondaryNavBackground), var(--secondaryNavBackgroundTextColor));
      }
    }
  }
}

.disabledButton {
  > button {
    background-color: grey !important;
    border: 2px grey solid !important;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}