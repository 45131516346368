@use "../../../../styles/variables.module" as *;

.roundIcon {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  color: #fff;
  line-height: 50px;
  font-size: 1.4rem;
  text-align: center;
  display: inline-block;
  vertical-align: middle;

  &Facebook {
    background-color: $facebook-color;
  }
  &Twitter {
    background-color: $twitter-color;
  }
  &Pinterest {
    background-color: $pinterest-color;
  }
  &Youtube {
    background-color: $youtube-color;
  }
  &Instagram {
    background-color: $instagram-color;
  }
  &Vimeo {
    background-color: $vimeo-color;
  }
  &Calendar {
    background-color: $admin-green-dark;
  }
  &Error {
    background-color: $error-red;
  }
  &Yelp {
    background-color: $yelp-color;
  }
  &Tripadvisor {
    background-color: $tripadvisor-color;
  }
  &Rss {
    background-color: $rss-color;
  }
  &Google {
    background-color: $google-color;
  }
  &Classroom {
    background-color: #0d8508;
  }
  &O365 {
    background-color: #da3b01;
  }
  &NewsFeed {
    background-color: #3e4d98;
  }
}
