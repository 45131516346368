@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";
@use "../../blocks/SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredInput.module";

.buttonEditor {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.toggleButton {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.buttonLabel {
  margin-bottom: 5px;
  font-size: 14px;
  opacity: 95%;
  font-weight: normal;
}

.buttonInputs {
  display: flex;
  flex-direction: column;
  gap: 15px;

  input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
  }

  small {
    text-align: right;
  }
}

.dropdown {
  background: white;
  margin: 10px 0;
  border-radius: 5px;
  @extend .input;

  > div:last-of-type {
    z-index: 2 !important;

    ul {
      max-height: 200px !important;
    }
  }

  &IsValid {
    @extend .inputValid;
  }
}

.toggleButtons {
  background-color: #D1D1D1 !important;
  width: fit-content;
  input + label {
    transition: color 0.25s ease;
  }

  input:checked + label {
    color: #FFFFFF;
  }

  input:not(:checked) + label {
    color: #808080;
  }

  div {
    background-color: $gray !important;
  }
}

.currentLinkContainer {
  span {
    font-size: 1rem;
    word-break: break-all;
  }

  button {
    margin-left: 8px;
    color: $admin-blue-dark;
    font-size: 1.3rem;
  }
}

.buttonPreviewContainer {
  display: flex;
  justify-content: center; // Center the preview button
  margin: 1rem 0;
}

.callToActionLink {
  background: var(--elements);
  color: var(--elementsTextColorNoBlocks);
  border: 2px solid white;
  margin: 0.15rem 0.25rem;
  padding: 0.5rem 1.25rem;
  text-align: center;
  display: inline-block;
  transition: padding 0.3s, margin 0.3s;

  &:hover {
    padding: 0.6rem 1.5rem;
    margin: 0;
    color: var(--secondaryNavBackgroundTextColor);
  }
}