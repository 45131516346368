@use "../../../../../styles/variables.module" as *;

.inputContainer {
  background-color: white;
  padding: 20px;
  margin: 5px 15px;
  border-radius: 15px;

  label {
    color: $admin-blue-dark;
    font-size: 1.2rem;
  }
}

.switchToggle {
  border: 1px solid #999;
  box-shadow: 1px 1px 1px #ccc;
  width: 25px;
  height: 24px;
  left: 0;
  border-radius: 12px;
  background: white;
  position: relative;
  transition: left .2s ease-in-out;
}

.switch {
  border: 1px solid #ccc;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  cursor: pointer;
  display: inline-block;

  &.on {
    background: $theme-color-green;
  }

  &.on .switchToggle {
    left: 23px;
  }

  &.disabled {
    cursor: not-allowed;
    background: lightgrey;
  }
}
